@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');


.dm-mono-light {
  font-family: "DM Mono", monospace;
  font-weight: 300;
  font-style: normal;
}

.dm-mono-regular {
  font-family: "DM Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.dm-mono-medium {
  font-family: "DM Mono", monospace;
  font-weight: 500;
  font-style: normal;
}

.dm-mono-light-italic {
  font-family: "DM Mono", monospace;
  font-weight: 300;
  font-style: italic;
}

.dm-mono-regular-italic {
  font-family: "DM Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.dm-mono-medium-italic {
  font-family: "DM Mono", monospace;
  font-weight: 500;
  font-style: italic;
}

/* Hide the default close button and its container */
.mantine-Drawer-header {
  display: none !important; /* Ensure it overrides any default styles */
}

/* Optional: If needed to handle the remaining space */
.mantine-Drawer-content {
  padding-top: 0 !important; /* Remove any top padding that was reserved for the header */
}

html{
  --primary-color:"#64FFDA";
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track
{
 border-radius: 10px;
 background-color: #112240;
}

::-webkit-scrollbar
{
 width: 4px;
 background-color: #112240;
}

::-webkit-scrollbar-thumb
{
 border-radius: 10px;
 background-color: #64FFDA;
}
